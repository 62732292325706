body {
    background-color: #7E0626;
    font-family: museo-sans, sans-serif;
    font-weight: 500;
}

.login-image-container {
    background-image: url("/src/graphics/bg/exterio_04.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

h1 {
    font-size: xx-large;
}

.center-container {
    position: relative;
}

.center-holder {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}


.transition-component {
    opacity: 0.01;
}

.transition-component.transition-component-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition-component {
    opacity: 1;
}

.transition-component.transition-component-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

@media (min-width: 768px) {
    .player-holder {
        width: 85vw;
    }

    .poll-holder {
        height: 100%;
    }
}

.player-bg {

    background-position: center;
    background-size: cover;
}

.poster-bg {

    background-position: center;
    background-size: cover;
}

.poster-bg-transparent {
    background-color: rgba(1,1,1,0.4);
}

.btn-selected {
    outline-color: #C8102E !important;
    outline-width: 1px;
    outline-style: solid;
}

.btn-deactivated {
    outline-color: #2E3192;
    opacity: 0.8;
    cursor: initial;
}

.btn {
    outline-color: #2E3192;
    outline-width: 1px;
    outline-style: solid;
    background-image: linear-gradient(rgba(27, 20, 100, 0.4), rgba(8, 6, 31, 0.5));
}

.btn-reactive:hover {
    outline-color: #C8102E;
}

.btn-reactive:active {
    outline-color: #C8102E;
}


.orkla-font-normal {
    font-family: museo-sans, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.orkla-font-heavy {
    font-family: museo-sans, sans-serif;
    font-weight: 900;
    font-style: normal;
}


.video {
    width: 100%;
    height: 100%;
    min-height: 50vw;
}


.jw-logo {
    opacity: 0!important;
}

.jw-logo-button {
    opacity: 0!important;
}
